import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import CustomCard from '../../components/CustomCard/CustomCard.js';
import { withPrefix } from 'gatsby-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Row className="resource-card-group" mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm style={{
        marginBottom: '1px'
      }} mdxType="Column">
    <CustomCard title="Displaying metadata" href={withPrefix('/channel-api-channel-page-design/displaying-metadata')} mdxType="CustomCard"></CustomCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterSm style={{
        marginBottom: '1px'
      }} mdxType="Column">
    <CustomCard title="Channel picture" href={withPrefix('/channel-api-channel-page-design/channel-picture')} mdxType="CustomCard"></CustomCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <CustomCard title="Featured videos on channel page" href={withPrefix('/channel-api-channel-page-design/featured-videos')} mdxType="CustomCard"></CustomCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <CustomCard title="Playlists on channel page" href={withPrefix('/channel-api-channel-page-design/playlists-on-channel-page')} mdxType="CustomCard"></CustomCard>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      